import React from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { SectionWixFooter } from '@wix/shoutout-email-react';

export const SectionWixFooterWithContent = (props: any) => {
  const { t } = useTranslation();
  const content = {
    dontReply: t('wix.footer.content.dont.reply'),
    helpCenter: t('wix.footer.content.help.center'),
    helpCenterUrl: t('wix.footer.content.help.center.url'),
    privacyPolicy: t('wix.footer.content.privacy.policy'),
    privacyPolicyUrl: t('wix.footer.content.privacy.policy.url'),
    termsOfUse: t('wix.footer.content.terms.of.use'),
    termsOfUseUrl: t('wix.footer.content.terms.of.use.url'),
    companyAddressLine: t('wix.footer.content.company.address'),
  };

  return <SectionWixFooter {...props} content={content} />;
};
